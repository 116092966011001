<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">运行监管</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">问题分类</a>
        </span>
      </div>
      <div class="framePage-body">
        <!-- 筛选 -->
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="班级编号" class="searchboxItem ci-full">
              <span class="itemLabel">班级编号:</span>
              <el-input
                v-model="projectCode"
                type="text"
                size="small"
                placeholder="请输入班级编号"
                clearable
              />
            </div>

            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel">班级名称:</span>
              <el-input
                v-model="liveProjectName"
                type="text"
                size="small"
                placeholder="请输入班级名称"
                clearable
              />
            </div>

            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="getData()"
            >
              查询
            </el-button>
          </div>
        </div>
        <!-- 列表 -->
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
              @cell-mouse-enter="M_Enter"
              @cell-mouse-leave="m_leave"
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                width="100px"
                fixed
              />
              <el-table-column
                label="班级编号"
                align="center"
                prop="projectCode"
                show-overflow-tooltip
                fixed
              />

              <el-table-column
                label="班级名称"
                align="center"
                prop="liveProjectName"
                show-overflow-tooltip
                fixed
              />

              <el-table-column
                label="开班单位"
                align="center"
                prop="compName"
                show-overflow-tooltip
                fixed
              />

              <el-table-column
                label="行政区划"
                align="center"
                prop="areaName"
                show-overflow-tooltip
                fixed
              />

              <el-table-column
                label="开班时间"
                align="center"
                show-overflow-tooltip
                prop="startDate"
              >
                <template slot-scope="scope">
                  {{ scope.row.startDate || "--" }}
                </template>
              </el-table-column>

              <el-table-column
                label="结束时间"
                align="center"
                show-overflow-tooltip
                prop="endDate"
              >
                <template slot-scope="scope">
                  {{ scope.row.endDate || "--" }}
                </template>
              </el-table-column>

              <el-table-column
                label="学员总数"
                align="center"
                show-overflow-tooltip
                prop="projectPeople"
              >
                <template slot-scope="scope">
                  {{ scope.row.projectPeople || "--" }}
                </template>
              </el-table-column>

              <el-table-column label="操作" align="center" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleLook(scope.row)"
                  >
                    下载档案
                  </el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <!-- 分页 -->
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";

export default {
  name: "liveFiles",
  components: {
    Empty,
    PageNum,
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  mixins: [List],
  data() {
    return {
      liveProjectName: "", // 班级名称
      projectCode: "", // 班级编号
      tableData: [], // 列表数据
      pageSize: 10, // 每页条数
    };
  },
  mounted() {},
  methods: {
    // 下载档案
    handleLook(row) {
      console.log("row", row.liveProjectId);
      let params = {
        liveProjectId: row.liveProjectId,
      };

      this.$post(
        "/liveProject/live-project/downloadLiveArchives",
        params,
        3000,
        true,
        3
      ).then((re) => {
        if (re.status == "0") {
          this.$post("/documentcenter/queryDownloadState", {
            taskIds: [re.data.data.taskId],
          }).then((res) => {
            let list = res.data;
            for (let item of list) {
              if (!this.downloadItems.includes(item.taskId)) {
                this.$store.dispatch("pushDownloadItems", item.taskId);
              } else {
                this.$message.warning(
                  "[" + item.fileName + "]已经申请下载,请耐心等待"
                );
              }
            }
          });
        } else {
          this.$message.error(re.message);
        }
      });
      return;

      // documentcenter/queryDownloadState
    },

    // 获取列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        liveProjectName: this.liveProjectName || "",
        projectCode: this.projectCode || "",
      };

      this.doFetch(
        {
          url: "/liveProject/live-project/queryLiveArchivesClassPageList",
          params,
          pageNum,
        },
        true,
        3
      );
    },
  },
};
</script>
<style lang="less">
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
<style lang="less">
.seeArea {
  .el-radio-group {
    display: flex;
    flex-direction: column;
    .el-radio :last-child {
      margin-top: 1rem;
    }
  }
}

.searchbox {
  padding: 0;
}
.cascader {
  position: relative;
  width: 100%;
  z-index: 10;
}
.tp {
  width: 50%;
  float: left;
  .img-el-upload {
    width: 63%;
    float: left;
    margin-right: 2%;
  }
  .tswz {
    float: left;
    font-size: 12px;
    line-height: 25px;
    color: #909399;
    .t1 {
      font-size: 14px;
      line-height: 40px;
      color: #606266;
    }
  }
}
.form-box {
  padding: 10px 20px;
  .form {
    min-width: 600px;
    padding: 20px;
  }
  .form-bg {
    background-color: rgb(247, 248, 253);
  }
}
</style>
<style lang="less" scoped>
.bannerBox .el-form-item__content .hide /deep/.el-upload--picture-card {
  display: none;
}
</style>
